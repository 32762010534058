import { Avatar, AvatarBody, AvatarImg, AvatarText } from '@playbooks/interface/avatars';
import { Icon } from '@playbooks/interface/icons';
import { LinkWrapper } from '@playbooks/interface/links';
import { truncate } from 'utils';

export const TableAvatar = ({ icon = 'database', thumbnail, title, href, tailwind }) => {
	// Render
	return (
		<LinkWrapper href={href} group='group' flex='shrink-0'>
			<Avatar display='flex-start' flex='shrink-0'>
				{thumbnail ? (
					<AvatarImg size='xs' src={thumbnail} alt={`${title} photo`} />
				) : (
					<Icon type='far' icon={icon} {...tailwind?.icon} />
				)}
				<AvatarBody hover='group-hover:underline' flex='shrink-0'>
					<AvatarText>{truncate(title, 40)}</AvatarText>
				</AvatarBody>
			</Avatar>
		</LinkWrapper>
	);
};
