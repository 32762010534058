import { Avatar } from 'molecules/avatars';
import { Skeleton } from 'molecules/skeletons';

export const UserAvatar = ({ type = 'card', user, loading = false, rootLink = '', tailwind }) => {
	const props = {
		icon: null,
		thumbnail: user.thumbnail,
		title: user.name,
		subtitle: `@${user.uuid}`,
		href: type === 'table' ? `${rootLink}/users/${user.uuid}` : `${rootLink}/${user.uuid}`,
		badge: user.role,
		tailwind,
	};

	// Render
	if (loading) return <Skeleton className='w-20' />;
	return <Avatar type={type} {...props} />;
};
